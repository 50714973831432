export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61')
];

export const server_loads = [];

export const dictionary = {
		"/(payment)": [6,[2]],
		"/mgmt": [14,[4]],
		"/mgmt/dashboard": [15,[4]],
		"/mgmt/profile": [16,[4]],
		"/mgmt/settings/accountCards": [17,[4,5]],
		"/mgmt/settings/accountCards/edit/[uuid]": [18,[4,5]],
		"/mgmt/settings/bankAccountCredentials/create/[konto]": [19,[4,5]],
		"/mgmt/settings/bankAccountCredentials/edit/[uuid]": [20,[4,5]],
		"/mgmt/settings/bankAccounts": [21,[4,5]],
		"/mgmt/settings/bankAccounts/create": [22,[4,5]],
		"/mgmt/settings/bankAccounts/edit/[uuid]": [23,[4,5]],
		"/mgmt/settings/bankAccounts/view/[uuid]": [24,[4,5]],
		"/mgmt/settings/carriers": [25,[4,5]],
		"/mgmt/settings/carriers/LBE/create": [28,[4,5]],
		"/mgmt/settings/carriers/LBE/edit/[uuid]": [29,[4,5]],
		"/mgmt/settings/carriers/LB/create": [26,[4,5]],
		"/mgmt/settings/carriers/LB/edit/[uuid]": [27,[4,5]],
		"/mgmt/settings/carriers/Router/create": [30,[4,5]],
		"/mgmt/settings/carriers/Router/edit/[uuid]": [31,[4,5]],
		"/mgmt/settings/carriers/SIP/create": [32,[4,5]],
		"/mgmt/settings/carriers/SIP/edit/[uuid]": [33,[4,5]],
		"/mgmt/settings/checkInvoice": [34,[4,5]],
		"/mgmt/settings/externalUsers": [35,[4,5]],
		"/mgmt/settings/externalUsers/create": [36,[4,5]],
		"/mgmt/settings/externalUsers/edit/[uuid]": [37,[4,5]],
		"/mgmt/settings/externalUsers/view/[uuid]": [38,[4,5]],
		"/mgmt/settings/global": [39,[4,5]],
		"/mgmt/settings/locations": [40,[4,5]],
		"/mgmt/settings/locations/create": [41,[4,5]],
		"/mgmt/settings/locations/edit/[uuid]": [42,[4,5]],
		"/mgmt/settings/locations/view/[uuid]": [43,[4,5]],
		"/mgmt/settings/paymentImport": [44,[4,5]],
		"/mgmt/settings/paymentImport/edit/[uuid]": [45,[4,5]],
		"/mgmt/settings/paymentJournal": [46,[4,5]],
		"/mgmt/settings/paymentJournal/edit/[uuid]": [47,[4,5]],
		"/mgmt/settings/paymentJournal/view/[uuid]": [48,[4,5]],
		"/mgmt/settings/paymentRelocate": [49,[4,5]],
		"/mgmt/settings/paymentReopen": [50,[4,5]],
		"/mgmt/settings/tariffs": [51,[4,5]],
		"/mgmt/settings/tariffs/create": [52,[4,5]],
		"/mgmt/settings/tariffs/edit/[uuid]": [53,[4,5]],
		"/mgmt/settings/tariffs/view/[uuid]": [54,[4,5]],
		"/mgmt/settings/users": [55,[4,5]],
		"/mgmt/settings/users/create": [56,[4,5]],
		"/mgmt/settings/users/edit/[uuid]": [57,[4,5]],
		"/mgmt/settings/users/view/[uuid]": [58,[4,5]],
		"/mgmt/settings/validLocs": [59,[4,5]],
		"/mgmt/settings/validLocs/create": [60,[4,5]],
		"/mgmt/settings/validLocs/edit/[uuid]": [61,[4,5]],
		"/(payment)/[[lang=lang]]/services/[l_terms=l_terms]": [13,[2,3]],
		"/(payment)/[[lang=lang]]/services/[l_privacy=l_privacy]": [12,[2,3]],
		"/(payment)/[[lang=lang]]/services/[l_imprint=l_imprint]": [11,[2,3]],
		"/(payment)/[[lang=lang]]/services/[l_contact=l_contact]": [10,[2,3]],
		"/(payment)/[[lang=lang]]/services/[l_confirmation=l_confirmation]": [9,[2,3]],
		"/(payment)/[[lang=lang]]/[l_relatives=l_relatives]": [8,[2,3]],
		"/(payment)/[[lang=lang]]/[l_facilities=l_facilities]": [7,[2,3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';